import { Card, Container, Text, Title, List, Badge } from "@mantine/core";
import "./ExperienceCard.css";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const ExperienceCard = ({ title, company, startDate, endDate, stackItems }) => {
  return (
    <Card shadow="md" radius="md" p="lg" className="experience-card">
      <Title order={3}>
        {title} @
        <Text component="span" c="#5e88bf" fw={700}>
          {" "}
          {company}
        </Text>
      </Title>
      <Container m={0} p={0}>
        {stackItems.map((stackItem) => (
          <Badge key={1} size="xs">
            {stackItem}
          </Badge>
        ))}
      </Container>
      <Text size="sm" c="dimmed" mt={5}>
        {startDate} - {endDate}
      </Text>
      <List
        spacing="md"
        mt="md"
        center
        icon={<FontAwesomeIcon icon={faChevronRight} size="xs" />}
      >
        <List.Item>
          Developed a Node.js smart home system through Facebook’s Messenger
          integrated with Bocco sensors and other smart devices (Nest camera,
          TPLink smart plugs) to derive conclusions about the current state of
          the home.
        </List.Item>
        <List.Item>
          Identified continuous improvements in data quality, design reports,
          and coding activities, presenting results and findings to internal
          business stakeholders.
        </List.Item>
        <List.Item>
          Relevant technologies/tools used: DialogFlow, Vision, AutoML,
          Messenger Bot API, MongoDB.
        </List.Item>
      </List>
    </Card>
  );
};

export default ExperienceCard;
