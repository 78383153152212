import React, { useEffect, useState } from "react";
import "./ExperienceSection.css";
import { Tabs } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import ExperienceCard from "../../components/experience-card/ExperienceCard";

function ExperienceSection() {
  const [orientation, setOrientation] = useState("vertical");

  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerWidth < 768 ? "horizontal" : "vertical");
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="container-fluid experience-background">
      <div className="experience-section" id="experience">
        <div className="container">
          <div className="row">
            <h2 className="experience-header">Journey So Far</h2>
          </div>
          <div className="row experience-tabs">
            <Tabs
              defaultValue="celestica-fulltime"
              orientation={orientation}
              classNames={{
                tabList: "experience-tab-list",
                tab: "experience-tab",
                panel: "experience-panel",
              }}
            >
              <Tabs.List>
                <Tabs.Tab value="celestica-fulltime">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    style={{ marginRight: "8px" }}
                  />
                  {orientation === "vertical"
                    ? "Full Time @ Celestica"
                    : "0.1."}
                </Tabs.Tab>
                <Tabs.Tab value="mcmaster">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    style={{ marginRight: "8px" }}
                  />
                  {orientation === "vertical" ? "McMaster University" : "02."}
                </Tabs.Tab>
                <Tabs.Tab value="celestica-intern">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    style={{ marginRight: "8px" }}
                  />
                  {orientation === "vertical" ? "Intern @ Celestica" : "03."}
                </Tabs.Tab>
                <Tabs.Tab value="ontario-tech">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    style={{ marginRight: "8px" }}
                  />
                  {orientation === "vertical"
                    ? "Ontario Tech University"
                    : "04."}
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="celestica-fulltime">
                <ExperienceCard
                  title="Software Engineer, Full-Time"
                  company="Celestica"
                  startDate="May 2024"
                  endDate="Present"
                  stackItems={[
                    "JavaScript",
                    "Node.js",
                    "Java",
                    "Spring Boot",
                    "Maven",
                    "TypeScript",
                    "Angular",
                    "Google Cloud Platform",
                    "Jenkins",
                  ]}
                />
              </Tabs.Panel>
              <Tabs.Panel value="mcmaster">
                <ExperienceCard
                  title="Master of Engineering, Systems and Technology"
                  company="McMaster University"
                  startDate="September 2024"
                  endDate="Present"
                  stackItems={["adwda"]}
                />
              </Tabs.Panel>
              <Tabs.Panel value="celestica-intern">
                <ExperienceCard
                  title="Software Engineer, Intern"
                  company="Celestica"
                  startDate="September 2019"
                  endDate="August 2024"
                  stackItems={[
                    "JavaScript",
                    "Node.js",
                    "Java",
                    "Spring Boot",
                    "Maven",
                    "TypeScript",
                    "Angular",
                    "Google Cloud Platform",
                    "Jenkins",
                  ]}
                />
              </Tabs.Panel>
              <Tabs.Panel value="ontario-tech">
                <ExperienceCard
                  title="Bachelor of Engineering, Software Engineering"
                  company="Ontario Tech University"
                  startDate="May 2024"
                  endDate="Present"
                  stackItems={["adwda"]}
                />
              </Tabs.Panel>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExperienceSection;
